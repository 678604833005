<template>
  <footer>
    <p class="text-center text-gray-300">
      <b>
        Incorp Technology © Copyright {{ date.getFullYear() }} - Todos os direitos
        reservados
      </b>
      <br />
      v1.4.0 - Desenvolvido por
      <a href="https://incorptech.com.br/" target="_blank">Incorp Technology</a>
    </p>
  </footer>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Footer",
  data() {
    return {
      date: new Date(),
    };
  },
});
</script>
