<template>
  <div>
    <Header></Header>
    <transition name="fade-in-down">
      <router-view></router-view>
    </transition>
    <Footer></Footer>
  </div>
</template>

<script>
import Vue from "vue";
import Header from "./Header.vue";
import Footer from "./Footer.vue";

export default Vue.extend({
  name: "LayoutBase",
  components: {
    Header,
    Footer,
  },
});
</script>
