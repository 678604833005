<template>
  <header>
    <div class="social-media ">
      <div class="media-content invisible">
        <ul>
          <li>
            <a href="#"><i class="fab fa-facebook"></i></a>
          </li>
          <li>
            <a href="#"><i class="fab fa-instagram"></i></a>
          </li>
          <li>
            <a href="#"><i class="fab fa-twitter"></i></a>
          </li>
        </ul>
      </div>
    </div>

    <div class="main-menu">
      <div class="menu-content">
        <div class="logo">
          <img :src="logo" alt="logo" />
        </div>

        <div class="block md:hidden">
          <Button
            :icon="true"
            color="gray-700"
            color-hover="green-700"
            background="transparent"
            size="large"
            :shadown="false"
            @action="openMenu"
          >
            <i class="fa fa-bars" aria-hidden="true"></i>
          </Button>
        </div>

        <nav :class="{ open, close: !open, 'md:block': true }">
          <ul>
            <li v-for="(item, index) in menu" :key="index">
              <router-link :to="{ path: item.page }">
                {{ item.texto }}
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import Button from "@/components/elements/Button.vue";

export default Vue.extend({
  name: "Header",
  data() {
    return {
      menu: [],
      logo: '../../assets/logo.png',
      open: false,
    };
  },
  components: {
    Button,
  },
  computed: {
    ...mapGetters("eleicaonet", {
      getParamHotsite: "getParamHotsite",
    }),
  },
  methods: {
    openMenu() {
      this.open = !this.open;
    },
  },
  mounted() {
    this.menu = this.getParamHotsite.menu;
    this.logo = this.getParamHotsite.logo;
  },
});
</script>
